module.exports ={
  "propertyService": "https://iwjpkyu355.execute-api.us-east-1.amazonaws.com/prd",
  "domusoApiService": "https://api.domuso.com",
  "appService": "https://app.domuso.com",
  "reservationService": "https://9xbvhk2lvl.execute-api.us-east-1.amazonaws.com/prd",
  "userPoolId": "us-east-1_2vMAPQtht",
  "authService": "https://d3pfgllpgh.execute-api.us-east-1.amazonaws.com/prd",
  "userPoolWebClientId": "1k1mgbsu3hbu5eqfosmng9rga0",
  "emailLinkBase": "https://app.domuso.com",
  "payWithUrl": "https://paywith.domuso.com",
  "supportEmail": "support@domuso.com",
  "residentWebUI": "https://resident.domuso.com",
  "maintenanceUrl": "https://maintenance.domuso.com/maintenance_tag.json",
  "env": "prd"
}